import { useEffect, useState } from "react";

export const Timer = ({ duration, onTimeout, setIsTimeout, isTimeout }) => {
  const [remainingTime, setRemainingTime] = useState(duration * 60);

  useEffect(() => {
    if (remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (remainingTime === 0) {
      setIsTimeout(true);
    }
  }, [remainingTime, setIsTimeout]);

  useEffect(() => {
    if (!isTimeout) {
      setRemainingTime(duration * 60);
    }
  }, [isTimeout, setRemainingTime, duration]);

  const minutes = Math.floor(remainingTime / 60);
  const remainingSeconds = remainingTime % 60;
  return (
    <>
      <span>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </span>
    </>
  );
};
