export default function MobileViewInDesktop({ children }) {
  return (
    <div className="bg-gray-200">
      <div
        id="scrollbar"
        className="w-[412px] mx-auto h-screen overflow-y-auto relative border bg-white"
      >
        {children}
      </div>
    </div>
  );
}
