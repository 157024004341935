import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { DataContext } from "../Context/DataProvider";
import EmployerRequirements from "../Pages/EmployerRequirements/EmployerRequirements";
import { ErrorPage } from "../Pages/ErrorPage/ErrorPage";
import MoreRequiredField from "../Pages/MoreRequiredField/MoreRequiredField";
import { PANDetails } from "../Pages/PANDetails/PANDetails";
import { PanEligibility } from "../Pages/PanEligibility/PanEligibility";

const PrivateRoute = ({ children }) => {
  const {
    metaData,
    responseError,
    isLoading,
    authToken,
    setRefetchMetaVersion,
  } = useContext(DataContext);
  const location = useLocation();
  const creditScore = metaData?.employeeProfile?.creditScore;
  const isPanVerified = metaData?.isPanVerified;
  const allowEntryAtLcs = metaData?.allowEntryAtLcs;
  const { selfDeclaration } = metaData?.employerRequirements || {};
  const { softpullMissingData } = metaData?.missingData || {};

  if (!authToken) {
    // User not authenticated, redirect to login page
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // if loading true show loading status
  if (isLoading) {
    return <Loader />;
  }

  // if response error true show the error page
  if (responseError) {
    return <ErrorPage />;
  }

  // check any required data missing or not
  if (softpullMissingData?.length) {
    return (
      <MoreRequiredField
        missingDataType={"softPull"}
        missingDataFieldsName={softpullMissingData}
        setRefetchMetaVersion={setRefetchMetaVersion}
      />
    );
  }

  // employer Requirements page will be shown by condition
  if (selfDeclaration === "INCOMPLETE") {
    return <EmployerRequirements />;
  }

  // set condition to allowed different user for different credit score
  let creditScoreCondition;
  if (allowEntryAtLcs) {
    creditScoreCondition = allowEntryAtLcs;
  } else {
    creditScoreCondition = creditScore >= 600 || creditScore === -1;
  }

  if (creditScoreCondition && isPanVerified === "Y") {
    // User has a credit score >= 600 and isPanVerified is "Y", allow access
    if (
      location.pathname === "/pan-verification" ||
      location.pathname === "/pan-eligibility"
    ) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  } else if (
    (!creditScoreCondition && isPanVerified === "N") ||
    (creditScoreCondition && isPanVerified === "N")
  ) {
    // User is Pan verified false and has allow for low credit score false then show PANDetails
    return <PANDetails />;
  } else if (isPanVerified === "Y" && creditScore < 600) {
    // User is Pan verified and has a credit score < 600, show PanEligibility
    return <PanEligibility />;
  }

  if (isPanVerified === "N" && !creditScore) {
    // User is not Pan verified and creditScore is not available, show PANDetails
    return <PANDetails />;
  } else if (isPanVerified === "N" && creditScore < 600) {
    // User is not Pan verified and has a credit score < 600, show PanEligibility
    return <PanEligibility />;
  } else if (isPanVerified === "N" && creditScore > 600) {
    // User is not Pan verified and creditScore credit score > 600, show PANDetails
    return <PANDetails />;
  }

  // Default fallback: Redirect to error page
  return <ErrorPage />;
};

export default PrivateRoute;
