import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { VerifyOtp } from "../Pages/VerifyOtp/VerifyOtp";
import { Login } from "../Pages/Login/Login";
import { DataContext } from "../Context/DataProvider";

const PublicRoute = ({ children }) => {
  const { userInfo, authToken } = useContext(DataContext);
  const location = useLocation();
  // if (loading) return "loading...";
  if (authToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (userInfo?.sessionId) {
    return <VerifyOtp />;
  } else {
    return <Login />;
  }
};

export default PublicRoute;
