import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";

export function ConfirmationDialog({ isOpen, setIsOpen, action }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-6 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 py-10 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl space-y-10 md:scale-[0.9]">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-grayText text-center"
                >
                  Are you sure you want <br />
                  to log out?
                </Dialog.Title>

                <div className="mt-4 flex gap-4">
                  <PrimaryButton
                    action={action}
                    customStyle={
                      "!w-[40%] !bg-transparent !shadow-none !border !border-[#858585] !rounded-xl !text-grayText !font-normal !py-4 !outline-none"
                    }
                  >
                    Logout
                  </PrimaryButton>
                  <PrimaryButton
                    action={() => setIsOpen(false)}
                    customStyle={
                      "!w-[60%] !border !border-primary !shadow-none !rounded-xl !font-normal !py-4"
                    }
                  >
                    Stay logged in
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
