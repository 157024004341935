import React, { useContext } from "react";
import { DataContext } from "../../Context/DataProvider";
import SalaryDeclaration from "../SalaryDeclaration/SalaryDeclaration";

export default function EmployerRequirements() {
  const { metaData, setRefetchMetaVersion } = useContext(DataContext);

  // let content;
  // if (selfDeclaration === "INCOMPLETE") {
  //   content = (
  //     <SalaryDeclaration setRefetchMetaVersion={setRefetchMetaVersion} />
  //   );
  // }

  return (
    <>
      <div>
        <SalaryDeclaration setRefetchMetaVersion={setRefetchMetaVersion} />
      </div>
    </>
  );
}
