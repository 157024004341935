import React, { createContext, useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
export const DataContext = createContext();

const INITIAL_LOAN_PROFILE = {
  employeeRequestedAmount: 0,
  effectiveInterestRate: 0,
  employeeLoanId: null,
  emiDetails: null,
  finalLoanAmount: 0,
  planInitialInfo: null,
};

const INITIAL_LOAN_PAYMENT_INFO = {
  authRequestId: null,
  subscriptionId: null,
};

const INVESTMENT_INFO = {
  transactionId: null,
  redirectUrl: null,
};

const LOAN_UNLOCK_INFO = {
  unlockAmount: 0,
};

const DataProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [employeeLoanProfile, setEmployeeLoanProfile] =
    useState(INITIAL_LOAN_PROFILE);
  const [authToken, setAuthToken] = useState(null);
  const [loanPaymentInfo, setLoanPaymentInfo] = useState(
    INITIAL_LOAN_PAYMENT_INFO
  );
  const [refetchMetaVersion, setRefetchMetaVersion] = useState(0);
  const [investmentInfo, setInvestmentInfo] = useState(INVESTMENT_INFO);
  const [loanUnlockInfo, setLoanUnlockInfo] = useState(LOAN_UNLOCK_INFO);
  const {
    data: metaData = {},
    isLoading: isLoadingMetaData,
    error: errorMetaData,
    refetch: refetchMetaData,
  } = useFetch("/general/meta", {
    skip: !authToken,
  });
  const {
    data: investorMeta,
    isLoading: isLoadingInvestorMetaData,
    error: errorInvestorMetaData,
  } = useFetch("/investor/general/investorMeta", {
    skip: true,
  });
  const investorMetaData = investorMeta?.data || {};

  const isLoading = isLoadingMetaData || isLoadingInvestorMetaData;
  const responseError = (errorMetaData || errorInvestorMetaData) || false;


  useEffect(() => {
    if (refetchMetaVersion > 0) {
      refetchMetaData();
    }
  }, [refetchMetaVersion, refetchMetaData])


  const dataInfo = {
    userInfo,
    setUserInfo,
    metaData,
    loanDetails,
    setLoanDetails,
    setEmployeeLoanProfile,
    employeeLoanProfile,
    responseError,
    isLoading,
    authToken,
    setAuthToken,
    loanPaymentInfo,
    setLoanPaymentInfo,
    setRefetchMetaVersion,
    refetchMetaData,
    investorMetaData,
    investmentInfo,
    setInvestmentInfo,
    loanUnlockInfo,
    setLoanUnlockInfo,
  };
  return (
    <DataContext.Provider value={dataInfo}>{children}</DataContext.Provider>
  );
};
export default DataProvider;
