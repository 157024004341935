import { Outlet } from "react-router-dom";
import MobileViewInDesktop from "../Components/MobileViewInDesktop/MobileViewInDesktop";
import ScrollTop from "../utils/ScrollTop";

export function Main() {
  return (
    <>
      <ScrollTop>
        <div className="md:hidden">
          <Outlet />
        </div>
        <div className="hidden md:block ">
          <MobileViewInDesktop>
            <Outlet />
          </MobileViewInDesktop>
        </div>
      </ScrollTop>
    </>
  );
}
