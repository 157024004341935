import React from "react";

function Loader({ message = "Loading", height = "screen", color = "primary" }) {
  return (
    <>
      <div className={`grid place-items-center h-${height}`}>
        <div className="space-y-3">
          <p className="font-semibold text-2xl text-primary text-center">
            {message}
          </p>
          <LoadingDot color={color} />
        </div>
      </div>
    </>
  );
}

export default Loader;

const LoadingDot = ({ color }) => {
  return (
    <>
      <div className="flex space-x-2 justify-center items-center">
        <span className="sr-only">Loading...</span>
        <div
          className={`h-4 w-4 bg-${color} rounded-full animate-bounce [animation-delay:-0.3s]`}
        ></div>
        <div
          className={`h-4 w-4 bg-${color} rounded-full animate-bounce [animation-delay:-0.15s]`}
        ></div>
        <div
          className={`h-4 w-4 bg-${color} rounded-full animate-bounce`}
        ></div>
      </div>
    </>
  );
};
