import axios from "axios";
import getBaseUrl from "./getBaseUrl";

const instance = axios.create({
  // baseURL: process.env.REACT_APP_baseURL,
});

instance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl()
    const token = JSON.parse(localStorage.getItem("keeperAuthToken"));
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("keeperAuthToken");
    }
    return Promise.reject(error);
  }
);

export default instance;
