import { useCallback, useEffect, useState } from "react";
import instance from "../utils/axiosInstance";

const useFetch = (url, options) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { skip = false } = options || {};

  // fetch data
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await instance.get(url);
      setData(response.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setIsLoading(false);
  }, [url]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  // fetch data only if skip is false
  useEffect(() => {
    if (skip) {
      setIsLoading(false);
      return;
    }
    fetchData();
  }, [fetchData, skip]);

  return { data, isLoading, error, refetch };
};

export default useFetch;
