import React, { Suspense, useContext, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { DataContext } from "../../Context/DataProvider";
const PanSecurityQuestion = React.lazy(() =>
  import("../PanSecurityQuestion/PanSecurityQuestion")
);
const PanVerification = React.lazy(() =>
  import("../PanVerification/PanVerification")
);

export const PANDetails = () => {
  const { setRefetchMetaVersion, metaData } = useContext(DataContext);
  const [questionInfo, setQuestionInfo] = useState(null);

  return (
    <>
      {questionInfo ? (
        <Suspense fallback={<Loader />}>
          <PanSecurityQuestion
            questionInfo={questionInfo}
            setQuestionInfo={setQuestionInfo}
            setRefetchMetaVersion={setRefetchMetaVersion}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<Loader />}>
          <PanVerification
            setQuestionInfo={setQuestionInfo}
            setRefetchMetaVersion={setRefetchMetaVersion}
            metaData={metaData}
          />
        </Suspense>
      )}
    </>
  );
};
