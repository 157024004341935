import { BackNavigation } from "../BackNavigation/BackNavigation";

export function PageTitle({
  title,
  setNavigateCondition,
  isNavigate = true,
  link = -1,
  isBackBtnShow = true,
  state = true,
  className,
}) {
  return (
    <>
      <div
        className={`flex items-center justify-center shadow-md py-5 ${className}`}
      >
        <div style={{ display: `${isBackBtnShow ? "block" : "none"}` }}>
          <BackNavigation
            link={isNavigate ? link : null}
            setState={setNavigateCondition}
            state={state}
          />
        </div>
        <h3 className="font-bold text-xl text-secondary capitalize">{title}</h3>
      </div>
    </>
  );
}
