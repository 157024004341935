import React, { useState } from "react";
import { createContext } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import app from "../firebase.init";
export const AuthContext = createContext();
const firebaseAuth = getAuth(app);
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const googleProvider = new GoogleAuthProvider();

  // google sign in
  const userGoogleSignIn = () => {
    return signInWithPopup(firebaseAuth, googleProvider);
  };

  const userInfo = {
    user,
    userGoogleSignIn,
  };
  return (
    <AuthContext.Provider value={userInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
