import instance from "./axiosInstance";

const checkVersion = async () => {
  // Check if the environment is production
  const isProduction = process.env.NODE_ENV === "production";

  const fetchAppVersion = async () => {
    try {
      const res = await instance.get("auth/getKeeperVersion");
      if (res?.status === 200) {
        return res?.data?.version?.webVersion || "1.0.0";
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isProduction) {
    // Attempt to fetch the app version
    const appVersion = await fetchAppVersion();
    if (appVersion) {
      let storedVersion = JSON.parse(localStorage.getItem("keeperVersion"));
      if (!storedVersion) {
        // If version is not stored, perform a hard reload, set the version, and clear the cache
        localStorage.setItem("keeperVersion", JSON.stringify(appVersion));
        clearCacheAndReload();
      } else if (storedVersion !== appVersion) {
        // If versions mismatch, perform a hard reload, update the version, and clear the cache
        localStorage.setItem("keeperVersion", JSON.stringify(appVersion));
        clearCacheAndReload();
      }
    }
  }
};

const clearCacheAndReload = () => {
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }

  // Perform a hard reload
  window.location.reload(true);
};

export default checkVersion;
