import { Player } from "@lottiefiles/react-lottie-player";

export function LottieWrapper({
  animationData,
  timeout,
  text,
  customWidth = "100%",
  customColor,
  textPosition,
  speed,
  isShowing,
}) {
  return (
    <>
      <div
        className={`absolute bg-white z-50 top-0 left-0 h-full transition-all duration-100`}
        style={{ opacity: isShowing ? 1 : 0 }}
      >
        <div className="flex flex-col justify-center items-center h-screen relative">
          <div className="relative">
            <Player
              src={animationData}
              style={{ width: customWidth, objectFit: "cover" }}
              speed={speed ? speed : 0.5}
              className="player z-50"
              duration={timeout}
              autoplay
              loop
            />
            <h1
              className={`text-xl text-primary font-bold text-center absolute w-full left-[50%] -translate-x-[50%] -z-10 ${
                textPosition ? textPosition : "bottom-[15%]"
              }`}
              style={{ color: customColor }}
            >
              {text}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
