import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { BackNavigation } from "../../Components/BackNavigation/BackNavigation";
import Loader from "../../Components/Loader/Loader";
import { DataContext } from "../../Context/DataProvider";
import instance from "../../utils/axiosInstance";
const HRMSLogin = React.lazy(() => import("../HRMSLogin/HRMSLogin"));
const EmployerSelection = React.lazy(() =>
  import("../EmployerSelection/EmployerSelection")
);
const EmailPhoneLogin = React.lazy(() =>
  import("../EmailPhoneLogin/EmailPhoneLogin")
);

export const Login = () => {
  const { setUserInfo } = useContext(DataContext);
  const [inputValue, setInputValue] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [employerSelectionStatus, setEmployerSelectionStatus] =
    useState("initial");
  const [selectedEmployer, setSelectedEmployer] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidInput(inputValue)) {
      setErrorMessage("");
      const userData = getUserData(inputValue);
      try {
        const { data } = await getOtp(userData);
        if (data.status === "Success") {
          const sessionId = data.otpSessionId;
          if (userData?.contactNumber) {
            setUserInfo({ phone: inputValue, sessionId });
          }
          if (userData?.email) {
            setUserInfo({ email: inputValue, sessionId });
          }
          navigate("/verify-otp");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorMessage("Invalid input");
      return false;
    }
  };

  // Check validation
  const isValidInput = (input) => {
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return mobileRegex.test(input) || emailRegex.test(input);
  };

  // getting user data by condition
  const getUserData = (input) => {
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return mobileRegex.test(input)
      ? { contactNumber: input }
      : emailRegex.test(input)
      ? { email: input }
      : {};
  };

  // send mobile number or email to getting session id for otp
  const getOtp = (userData) => {
    return instance.post("/auth/getOtp", userData);
  };

  return (
    <div className="px-5">
      {employerSelectionStatus !== "initial" ? (
        <BackNavigation
          setState={setEmployerSelectionStatus}
          state={"initial"}
        />
      ) : undefined}
      {employerSelectionStatus === "B2B" ? (
        <Suspense fallback={<Loader />}>
          <EmailPhoneLogin
            handleSubmit={handleSubmit}
            employerSelectionStatus={employerSelectionStatus}
            errorMessage={errorMessage}
            setInputValue={setInputValue}
          />
        </Suspense>
      ) : employerSelectionStatus === "B2C" ? (
        <Suspense fallback={<Loader />}>
          <HRMSLogin
            selectedEmployer={selectedEmployer}
            setEmployerSelectionStatus={setEmployerSelectionStatus}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<Loader />}>
          <EmployerSelection
            setEmployerSelectionStatus={setEmployerSelectionStatus}
            selectedEmployer={selectedEmployer}
            setSelectedEmployer={setSelectedEmployer}
          />
        </Suspense>
      )}
    </div>
  );
};
