import { useContext, useState } from "react";
import { Timer } from "../Timer/Timer";
import { DataContext } from "../../Context/DataProvider";
import instance from "../../utils/axiosInstance";

export function ResendOtp({ setOtp }) {
  const { userInfo, setUserInfo } = useContext(DataContext);
  const [otpTimer, setOtpTimer] = useState(0.5); // timer duration in minutes
  const [isTimeout, setIsTimeout] = useState(false);

  // resend otp
  const handleResendOtp = async () => {
    setOtp(new Array(6).fill(""));
    let userData;
    if (userInfo?.phone) {
      userData = { contactNumber: userInfo?.phone };
    }
    if (userInfo?.email) {
      userData = { email: userInfo?.email };
    }
    try {
      const { data } = await instance.post("/auth/getOtp", userData);
      if (data.status === "Success") {
        const sessionId = data.otpSessionId;
        setUserInfo({ ...userInfo, sessionId });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <p className="text-sm text-center mt-10 text-gray-500">
        Didn’t receive it?{" "}
        {isTimeout ? (
          <button
            className="text-primary"
            onClick={() => {
              handleResendOtp();
              setIsTimeout(false);
            }}
          >
            Resend
          </button>
        ) : (
          <span>
            Resend in{" "}
            <Timer
              duration={otpTimer}
              setIsTimeout={setIsTimeout}
              isTimeout={isTimeout}
            />
          </span>
        )}
      </p>
    </>
  );
}
