import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { Main } from "../Layout/Main";
import { ErrorPage } from "../Pages/ErrorPage/ErrorPage";
import { Home } from "../Pages/Home/Home";
import { Login } from "../Pages/Login/Login";
import { PANDetails } from "../Pages/PANDetails/PANDetails";
import { PanEligibility } from "../Pages/PanEligibility/PanEligibility";
import { VerifyOtp } from "../Pages/VerifyOtp/VerifyOtp";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import PublicRoute from "../PublicRoute/PublicRoute";

const InterestRate = React.lazy(() =>
  import("../Pages/InterestRate/InterestRate")
);
const EMIPlan = React.lazy(() => import("../Pages/EMIPlan/EMIPlan"));
const UserDocumentUpload = React.lazy(() =>
  import("../Pages/UserDocumentUpload/UserDocumentUpload")
);
const FAQ = React.lazy(() => import("../Pages/FAQ/FAQ"));
const Terms = React.lazy(() => import("../Pages/Terms/Terms"));
const PrivacyPolicy = React.lazy(() =>
  import("../Pages/PrivacyPolicy/PrivacyPolicy")
);
const ApplicationSuccess = React.lazy(() =>
  import("../Pages/ApplicationSuccess/ApplicationSuccess")
);
const LoanHistory = React.lazy(() =>
  import("../Pages/LoanHistory/LoanHistory")
);
const ESign = React.lazy(() => import("../Pages/PhonePeFlow/ESign/ESign"));
const AssetsPledge = React.lazy(() =>
  import("../Pages/AssetsPledge/AssetsPledge")
);
const MandateStatusChecking = React.lazy(() =>
  import("../Pages/MandateStatusChecking/MandateStatusChecking")
);
const UPISubscriptionState = React.lazy(() =>
  import("../Pages/PhonePeFlow/UPISubscriptionState/UPISubscriptionState")
);
const LowScoreGetAmount = React.lazy(() =>
  import("../Pages/LowScoreGetAmount/LowScoreGetAmount")
);
const MoreRequiredField = React.lazy(() =>
  import("../Pages/MoreRequiredField/MoreRequiredField")
);

const Investment = React.lazy(() =>
  import("../Pages/InvestorFlow")
);

const InvestmentPlanDetails = React.lazy(() =>
  import("../Pages/InvestorFlow/InvestmentPlanDetails/InvestmentPlanDetails")
);

const InvestmentSuccess = React.lazy(() =>
  import("../Pages/InvestorFlow/InvestmentSuccess/InvestmentSuccess")
);

const InvestmentTerms = React.lazy(() =>
  import("../Pages/InvestorFlow/InvestmentTerms/InvestmentTerms")
);

const InvestmentCKYC = React.lazy(() =>
  import("../Pages/InvestorFlow/InvestmentCKYC/InvestmentCKYC")
);

const InvestmentPaymentStatus = React.lazy(() =>
  import("../Pages/InvestorFlow/InvestmentPaymentStatus/InvestmentPaymentStatus")
);
const LoanForeclosure = React.lazy(() =>
  import("../Pages/LoanForeclosure/LoanForeclosure")
);


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",

        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/home",
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/login",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/verify-otp",
        element: (
          <PublicRoute>
            <VerifyOtp />
          </PublicRoute>
        ),
      },

      {
        path: "/document-submit",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <UserDocumentUpload />
            </Suspense>
          </PrivateRoute>
        ),
      },

      {
        path: "/interest-rate",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InterestRate />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/emi-plan",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <EMIPlan />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/application-success",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <ApplicationSuccess />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/pan-verification",
        element: (
          <PrivateRoute>
            <PANDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/pan-eligibility",
        element: (
          <PrivateRoute>
            <PanEligibility />
          </PrivateRoute>
        ),
      },
      {
        path: "/terms",
        element: (
          <Suspense fallback={<Loader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "/faq",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <FAQ />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/loan-history",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <LoanHistory />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/upi-subscription-state",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <UPISubscriptionState />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/e-sign",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <ESign />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/assets-pledge",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <AssetsPledge />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/mandate-status-checking",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <MandateStatusChecking />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/low-score-get-amount",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <LowScoreGetAmount />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/more-required-details",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <MoreRequiredField />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/add-funds",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <Investment />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/plan-details/:planId",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InvestmentPlanDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/investment-success",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InvestmentSuccess />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/terms-and-conditions",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InvestmentTerms />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/ckyc",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InvestmentCKYC />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/investment/payment-status-checking",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <InvestmentPaymentStatus />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: "/loan-foreclosure",
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loader />}>
              <LoanForeclosure />
            </Suspense>
          </PrivateRoute>
        ),
      },

      // {
      //   path: "/employer-requirements",
      //   element: (
      //     <PrivateRoute>
      //       <EmployerRequirements />
      //     </PrivateRoute>
      //   ),
      // },
    ],
  },
]);
