export function PrimaryButton({
  action,
  children,
  disabledCondition,
  customStyle,
  buttonType = "submit",
}) {
  return (
    <>
      <button
        id="primary-button"
        onClick={action}
        type={buttonType}
        className={`relative overflow-hidden cursor-pointer rounded-lg bg-primary p-4 font-semibold capitalize text-white transition duration-200 ease-in-out shadow-shadowY4px w-full place-self-end disabled:bg-gray-400 disabled:text-gray-300 disabled:cursor-not-allowed ${customStyle}`}
        disabled={disabledCondition}
      >
        {children}
      </button>
    </>
  );
}
